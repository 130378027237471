<template>
  <div>
    <b-modal
      :id="idModalDelete"
      centered
      :hide-footer="true"
      :hide-header="true"
    >
      <div class="confirm-modal-delete">
        <div class="text-warning">
          <feather-icon :icon="icon" />
        </div>
        <div class="title">
          Bạn chắc chắn xóa
        </div>
        <!--Select lựa chọn kiểu người dùng mới sau khi xóa -->
        <b-col>
          <validation-observer ref="rule">
            <b-form class="content-form">
              <b-form-group label-for="basicInput">
                <label for="InputHelp">Chuyển đến quy chuẩn<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="parentId"
                  rules="required"
                  :custom-messages="customMessages"
                >
                  <LightSelect
                    v-model="parentId"
                    :idMinus="deletedStructId"
                    :isMinus="isMinus"
                    :class="{'is-invalid': !!errors.length}"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-form>
          </validation-observer>
        </b-col>

        <div class="action">
          <b-button
            variant="primary"
            class="mr-1 btn-left"
            @click="accept"
          >
            <!-- {{ $t("common.ok-title") }} -->
            Đồng ý
          </b-button>
          <b-button
            v-if="!isNotification"
            variant="outline-danger"
            @click="cancel"
          >
            <!-- {{ $t("common.cancel-title") }} -->
            Hủy bỏ
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton, BFormGroup, BForm, BCol,
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import LightSelect from './LightSelectUpdate.vue'

export default {
  components: {
    BButton,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BCol,
    LightSelect,
  },
  props: {
    isNotification: {
      type: Boolean,
      default: false,
    },
    isNoClose: {
      type: Boolean,
      default: false,
    },
    idModalDelete: {
      type: String,
      default: 'modalCreate',
    },
    icon: {
      type: String,
      default: 'AlertCircleIcon',
    },
    deletedStructId: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      parentId: '',
      customMessages: {
        required: 'Cơ cấu tổ chức cần chuyển là bắt buộc',
      },
      isMinus: true,
    }
  },
  methods: {
    accept(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$emit('accept', this.parentId, this.$refs.rule)
    },
    cancel() {
      this.$bvModal.hide(this.idModalDelete)
      this.parentId = ''
    },
  },
}
</script>
<style lang="scss">
.confirm-modal-delete {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
  .text-warning {
    svg {
      width: 80px;
      height: 80px;
    }
  }
  .title {
    margin-top: 10px;
    font-weight: 500;
    font-size: 21px;
  }
  .action {
    margin-top: 25px;
  }
  .description {
    font-size: 16px;
    text-align: center;
  }
}
@media (max-width: 576px) {
  .confirm-modal-delete {
    padding: 20px;
  }
}
@media (max-width: 375px) {
  .confirm-modal-delete {
    .action {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .btn-left {
        margin-right: 0px !important;
      }
      .btn {
        margin-top: 10px;
      }
    }
  }
}
</style>

<template>
  <b-modal
    :id="id"
    size="lg"
    :title="title"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    @ok="handleCreate($event)"
    @hidden="resetData"
  >
    <div class="modal-report">
      <h3>
        <feather-icon
          icon="InfoIcon"
          size="20"
          class="text-body"
        />
        Thông tin vị trí</h3>
      <validation-observer ref="rule">
        <b-form class="content-form">
          <b-row>

            <b-col
              sm
              lg="4"
            >
              <b-form-group
                styles="height: calc(100% - 1rem);"
              >

                <validation-provider
                  #default="{ errors }"
                  name="typeOfLabor"
                  rules="required"
                  :custom-messages="typeOfLabor"
                >
                  <label for="basicInput">Loại loại động<span class="required">(*)</span></label>
                  <v-select
                    v-model="dataInput.typeOfLabor"
                    :reduce="item => item.key"
                    label="value"
                    :options="dataCombobox"
                    :placeholder="'Lựa chọn loại lao động'"
                  >
                    <template v-slot:no-options="{ searching }">
                      <template v-if="searching">
                        Không có kết quả.
                      </template>
                      <em
                        v-else
                      >Không có dữ liệu</em>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm
              lg="4"
            >
              <b-form-group
                class="form-group-custom"
              >
                <label
                  for="InputHelp"
                >Nhiệt độ tối thiểu trong không khí</label>
                <b-form-input
                  v-model="dataInput.temperatureMin"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col
              sm
              lg="4"
            >
              <b-form-group
                class="form-group-custom"
              >
                <label
                  for="InputHelp"
                >Nhiệt độ tối đa trong không khí</label>
                <b-form-input
                  v-model="dataInput.temperatureMax"
                  type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm
              lg="4"
            >
              <b-form-group
                class="form-group-custom"
              >
                <label
                  for="InputHelp"
                >Độ ẩm tối thiểu trong không khí</label>
                <b-form-input
                  v-model="dataInput.humidityMin"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col
              sm
              lg="4"
            >
              <b-form-group>
                <label
                  for="InputHelp"
                >Độ ẩm tối đa trong không khí</label>
                <b-form-input
                  v-model="dataInput.humidityMax"
                  type="number"
                />
              </b-form-group>
            </b-col>
          <!-- <b-col sm>
            <b-form-group>
              <label for="InputHelp">cường độ bức xạ nhiệt</label>
              <b-form-input
                v-model="dataInput.respiratory"
                type="number"
              />
            </b-form-group>
          </b-col> -->
          </b-row>
          <b-row>
            <b-col
              sm
              lg="4"
            >
              <b-form-group
                class="form-group-custom"
              >
                <label
                  for="InputHelp"
                >Tốc độ chuyển động không khí tối thiểu</label>
                <b-form-input
                  v-model="dataInput.windSpeedMin"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col
              sm
              lg="4"
            >
              <b-form-group
                class="form-group-custom"
              >
                <label
                  for="InputHelp"
                >Tốc độ chuyển động không khí tối đa</label>
                <b-form-input
                  v-model="dataInput.windSpeedMax"
                  type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <h3>
            <feather-icon
              icon="ClockIcon"
              size="20"
              class="text-body"
            />
            Thời gian tiếp xúc</h3>

          <b-row>
            <b-col
              sm
              lg="4"
            >
              <b-form-group>
                <label for="InputHelp">Liên tục</label>
                <b-form-input
                  v-model="dataInput.contactContinuous"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col
              sm
              lg="4"
            >
              <b-form-group>
                <label for="basicInput">25%</label>
                <b-form-input
                  v-model="dataInput.contact25"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col
              sm
              lg="4"
            >
              <b-form-group>
                <label for="InputHelp">50%</label>
                <b-form-input
                  v-model="dataInput.contact50"
                  type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm
              lg="4"
            >
              <b-form-group>
                <label for="InputHelp">75%</label>
                <b-form-input
                  v-model="dataInput.contact75"
                  type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>

      </validation-observer>
    </div>
  </b-modal>
</template>

<script>

import {
  BRow,
  BCol,
  BFormGroup,
  BModal,
  BFormInput,
  BForm,
  BFormTextarea,
  BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import VSelect from 'vue-select'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    BForm,
    BFormTextarea,
    BButton,
    DateTimePicker,
    VSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    dataDetail: {
      type: Object,
      default: _ => {},
    },
  },
  data() {
    return {
      required,
      dataCombobox: [],
      dataInput: {
        typeOfLabor: '',
        temperatureMin: 0,
        temperatureMax: 0,
        humidityMin: 0,
        humidityMax: 0,
        windSpeedMin: 0,
        windSpeedMax: 0,
        name: '',
        contactContinuous: 0,
        contact75: 0,
        contact50: 0,
        contact25: 0,
      },
      typeOfLabor: {
        required: 'loại quy chuẩn là bắt buộc',
      },
    }
  },
  watch: {
    dataDetail(val) {
      if (val) {
        this.dataInput = {
          typeOfLabor: this.dataDetail.typeOfLabor,
          temperatureMax: this.dataDetail.temperatureMax,
          temperatureMin: this.dataDetail.temperatureMin,
          humidityMin: this.dataDetail.humidityMin,
          humidityMax: this.dataDetail.humidityMax,
          windSpeedMin: this.dataDetail.windSpeedMin,
          windSpeedMax: this.dataDetail.windSpeedMax,
          name: this.dataDetail.name,
          contactContinuous: this.dataDetail.contactContinuous,
          contact75: this.dataDetail.contact75,
          contact50: this.dataDetail.contact50,
          contact25: this.dataDetail.contact25,
        }
      }
    },
  },
  created() {
    this.getCombobox()
  },
  methods: {
    handleCreate(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
    },
    async getCombobox(urlQuery) {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_TYPE_OF_LABORLEVEL)
      this.dataCombobox = data?.data
    },
    resetData() {
      this.dataInput = {
        typeOfLabor: '',
        temperatureMin: 0,
        temperatureMax: 0,
        humidityMin: 0,
        humidityMax: 0,
        windSpeedMin: 0,
        windSpeedMax: 0,
        name: '',
        contactContinuous: 0,
        contact75: 0,
        contact50: 0,
        contact25: 0,
      }
    },
  },

}
</script>

<style lang="scss">
.modal-report {
  .action {
    display: flex;
    justify-content: end;
  }
  .content-form {

  }
}
</style>

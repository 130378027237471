<template>
  <div>
    <!--phần header-->
    <button-all-header
      :contentBtnAdd="'Thêm quy chuẩn'"
      :arrayExcel="[]"
      :hideImportFile="false"
      :hideExportFile="false"
      :hideDowload="false"
      :showBtnMultiDelete="showBtnMultiDelete"
      @clickDelete="deleteItems"
      @clickAdd="showModalCreate"
      @search="search($event)"
    />
    <b-table-simple
      hover
      small
      caption-top
      responsive
      class="table-simple"
    >
      <colgroup><col><col></colgroup>
      <colgroup><col><col><col></colgroup>
      <colgroup><col><col></colgroup>
      <b-thead>
        <b-tr>
          <b-th
            style="width: 50px;"
            rowspan="2"
          ><b-form-checkbox @change="selectAllCheckbox($event)" /></b-th>
          <b-th
            rowspan="2"
            colspan="1"
          >
            LOẠI LAO ĐỘNG
          </b-th>
          <b-th
            rowspan="2"
            colspan="1"
          >
            KHOẢNG NHIỆT ĐỘ KHÔNG KHÍ
          </b-th>
          <b-th
            rowspan="2"
            colspan="1"
          >
            TỐC ĐỘ CHUYỂN ĐỘNG KHÔNG KHÍ
          </b-th>
          <b-th
            rowspan="2"
            colspan="1"
          >
            ĐỘ ẨM KHÔNG KHÍ
          </b-th>
          <!-- <b-th
            colspan="1"
            style="width: 140px;"
          >
            CƯỜNG ĐỘ BỨC XẠ NHIỆT
          </b-th> -->
          <b-th colspan="4">
            THỜI GIAN TIẾP XÚC
          </b-th>
          <b-th
            colspan="1"
            rowspan="2"
            style="width: 150px;"
          >
            CHỨC NĂNG
          </b-th>
        </b-tr>
        <b-tr>

          <!-- <b-th></b-th> -->
          <b-th>LIÊN TỤC</b-th>
          <b-th>25%</b-th>
          <b-th>50%</b-th>
          <b-th>75%</b-th>
        </b-tr>
      </b-thead>
      <b-tbody v-if="dataTable.length">
        <b-tr
          v-for="(item, index) in dataTable"
          :key="index"
        >
          <b-td
            class="pb-25"
            style="width: 50px;"
          >
            <b-form-checkbox
              :checked="checkId.includes(item.id)"
              @change="selectRowTable($event, item.id)"
            />
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.name }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.temperatureMin }} - {{ item.temperatureMax }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.windSpeedMin }} - {{ item.windSpeedMax }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.humidityMin }} - {{ item.humidityMax }}
          </b-td>
          <!-- <b-td class="pb-1 pt-1">
            {{ item.content }}
          </b-td> -->
          <b-td class="pb-1 pt-1">
            {{ item.contactContinuous }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.contact25 }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.contact50 }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.contact75 }}
          </b-td>
          <b-td
            class="pb-1 pt-1"
            style="width: 150px;"
          >
            <feather-icon
              icon="Edit3Icon"
              size="18"
              class="text-body ml-2"
              @click="showModalEdit(item.id)"
            />
            <feather-icon
              icon="Trash2Icon"
              size="18"
              class="text-body ml-2"
              @click="deleteItem(item.id)"
            />
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tbody
        v-else
      >
        <div class="empty-table d-block">
          <div class="w-100 d-flex align-items-center justify-content-center">
            <b-img
              class="img-empty-table"
              fluid
              :src="$emptyImg"
              alt="empty table"
            />
          </div>
        </div>
      </b-tbody>
    </b-table-simple>

    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="handlePageClick"
    />

    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />

    <modal-create-temperature
      :id="modalEdit"
      :title="modalType === 'add' ? 'Thêm quy chuẩn nhiệt độ' :'Chỉnh sửa quy chuẩn nhiệt độ'"
      :dataDetail="dataDetail"
      @accept="hanldEdit($event)"
    />
  </div>
</template>

<script>
import {
  BTableSimple,
  BTbody,
  BTr,
  BTh,
  BTd,
  BThead,
  BFormCheckbox,
  BImg,
} from 'bootstrap-vue'

import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'
import ModalCreateTemperature from '../component/ModalCreateTemperature.vue'

export default {
  components: {
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BThead,
    ButtonAllHeader,
    BFormCheckbox,
    ConfirmModal,
    MyPagination,
    ModalCreateTemperature,
    BImg,
  },
  data() {
    return {
      dataTable: [],
      totalRecord: 0,
      urlQuery: {
        search: '',
        pageSize: 10,
        pageNumber: 1,
      },
      showBtnMultiDelete: false,
      modalType: null,
      idDetail: null,
      dataDetail: {},
      confirmModalId: 'confirm-modal',
      modalContent: '',
      deleteIds: [],
      checkId: [],
      modalEdit: 'modal-create-temperature',
    }
  },
  created() {
    this.getData(this.urlQuery)
  },
  methods: {
    async getData(urlQuery) {
      const { data } = await axiosApiInstance.get(ConstantsApi.API_TEMPERATURE, {
        params: urlQuery,
      })
      this.dataTable = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.showBtnMultiDelete = false
    },
    // Mở modal tạo và chỉnh sửa kiểm định viên
    showModalCreate() {
      this.modalType = 'add'
      this.$bvModal.show(this.modalEdit)
    },

    // Mở modal tạo và chỉnh sửa kiểm định viên
    async showModalEdit(id) {
      this.modalType = 'edit'
      this.idDetail = id
      this.$bvModal.show(this.modalEdit)
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_TEMPERATURE}${this.idDetail}`)
      this.dataDetail = data
    },

    // Xử lý khi ấn nút phân trang
    handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.getData(this.urlQuery)
    },

    // tìm kiếm lộ trình đào tạo
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = val
        this.getData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = ''
        this.getData(this.urlQuery)
      }
    },
    // Chọn người dùng để thực hiện action
    selectRowTable(event, val) {
      if (event === true) {
        this.checkId.push(val)
      }
      if (event === false) {
        const a = this.checkId.findIndex(x => x === val)
        this.checkId.splice(a, 1)
      }
      if (this.checkId.length > 0) {
        this.showBtnMultiDelete = true
      } else if (this.checkId.length < 1 && event === false) {
        this.showBtnMultiDelete = false
      }
    },

    // Chọn xóa tất cả
    selectAllCheckbox(event) {
      if (event === true) {
        this.showBtnMultiDelete = true
        this.checkId = this.dataTable.map(element => element.id)
      } else if (event === false) {
        this.showBtnMultiDelete = false
        this.checkId = []
      }
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.checkId
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // xóa 1 người dùng
    deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xóa người dùng của hệ thống
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.delete(ConstantsApi.DELETE_TEMPERATURE, {
        params: {
          model: JSON.stringify(this.deleteIds),
          observationMonitorId: this.$route.params.id,
        },
      }).then(res => {
        if (res.status === 200) {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Xóa thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
        this.$bvModal.hide(this.modalEdit)
        this.getData(this.urlQuery)
        this.checkId = []
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast(e.response.data?.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Action tạo mới và chỉnh sửa cơ cấu tổ chức
    async hanldEdit(val) {
      const payload = {
        ...val,
        typeOfLabor: parseInt(val.typeOfLabor, 10),
        temperatureMin: parseInt(val.temperatureMin, 10),
        temperatureMax: parseInt(val.temperatureMax, 10),
        humidityMin: parseInt(val.humidityMin, 10),
        humidityMax: parseInt(val.humidityMax, 10),
        windSpeedMin: parseInt(val.windSpeedMin, 10),
        windSpeedMax: parseInt(val.windSpeedMax, 10),
        contactContinuous: parseInt(val.contactContinuous, 10),
        contact75: parseInt(val.contact75, 10),
        contact50: parseInt(val.contact50, 10),
        contact25: parseInt(val.contact25, 10),
      }
      if (this.modalType === 'edit') {
        const data = {
          ...payload,
          id: this.idDetail,
        }
        this.$showAllPageLoading()
        await axiosApiInstance.put(ConstantsApi.EDIT_TEMPERATURE, data).then(res => {
          if (res.status === 200) {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast('Cập nhật thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.getData(this.urlQuery)
            this.$bvModal.hide(this.modalEdit)
            this.checkId = []
          } else {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast(`${res.data?.errors[0]}`, {
              title: 'Thông báo',
              variant: 'danger',
              toaster: this.$toastPosition,
              solid: true,
            })
          }
        })
      }
      if (this.modalType === 'add') {
        this.$showAllPageLoading()
        await axiosApiInstance.post(ConstantsApi.CREATE_TEMPERATURE, payload).then(res => {
          if (res.status === 200) {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast('Tạo thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.$bvModal.hide(this.modalEdit)
            this.getData(this.urlQuery)
            this.checkId = []
          } else {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast(`${res.data?.errors[0]}`, {
              title: 'Thông báo',
              variant: 'danger',
              toaster: this.$toastPosition,
              solid: true,
            })
          }
        })
      }
    },
  },
}
</script>

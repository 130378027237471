<template lang="">
  <tree-select
    v-model="selected"
    :disabled="disableProp"
    :normalizer="normalizer"
    :options="dataTreeLight"
    :auto-load-root-options="true"
    :placeholder="'Lựa chọn quy chuẩn ánh sáng'"
    :noChildrenText="'không có quy chuẩn ánh sáng con'"
    :default-expand-level="1"
    @input="selectChange"
    @select="checkNameText"
  >

  </tree-select>
</template>
<script>
import TreeSelect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    TreeSelect,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {
      default: null,
    },

    disableProp: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      selected: null,
      normalizer(node) {
        if (node.children) {
          return {
            id: node.id,
            label: node.name,
            children: node.children || [],
          }
        }
        delete node.children
        return {
          id: node.id,
          label: node.name,
        }
      },
      dataTreeLight: [],
    }
  },
  watch: {
    value(val) {
      this.selected = val
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    async  fetchData() {
      const { data } = await axiosApiInstance.get(ConstantsApi.GET_TREEVIEW_LIGHT)
      this.dataTreeLight = data?.data
    },
    checkNameText(value) {
      this.$emit('checkNameText', value)
    },
    selectChange(value) {
      this.$emit('input', value)
    },
    showAddModal() {
      this.$emit('addNewItem')
    },
  },
}
</script>
<style lang="scss" scoped>
.header-template {
  margin: 0px 10px;
  text-align: left;
  color: #227ff4;
  svg {
    fill: transparent !important;
  }
}

.btn-text-select {
  font-size: 12px;
  line-height: 18px;
  margin-left: 10px;
}
.border-item-select {
  border-bottom: 1px;
  border-color: #ebe9f1;
  width: 100%;
}
</style>

<style lang="scss">
.is-invalid {
  .vue-treeselect__control {
    border-color: #ea5455;
    background-repeat: no-repeat;
    background-size: calc(0.725em + 0.438rem) calc(0.725em + 0.438rem);
  }
}
</style>

<template>
  <b-modal
    :id="id"
    :title="title"
    size="lg"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    @ok="handleCreate($event)"
    @hidden="resetData"
  >
    <div class="modal-report">
      <h3>
        <feather-icon
          icon="InfoIcon"
          size="20"
          class="text-body"
        />
        Thông tin vị trí</h3>

      <validation-observer ref="rule">
        <b-form class="content-form">
          <b-row>
            <b-col
              sm
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  :rules="isCheckInput ? 'required': ''"
                  :custom-messages="name"
                >
                  <label for="InputHelp">Tên vị trí </label>
                  <b-form-input
                    v-model="dataInput.name"
                    :state="errors.length > 0 ? false:null"
                    @input="handleCheckedInput()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm
            >
              <b-form-group>
                <label for="basicInput">Loại quy chuẩn</label>

                <validation-provider
                  #default="{ errors }"
                  name="noiseGroupId"
                  :rules="isCheckInput ? 'required': ''"
                  :custom-messages="noiseGroupId"
                >
                  <v-select
                    v-model="dataInput.noiseGroupId"
                    :reduce="item => item.key"
                    label="value"
                    :options="dataCombobox"
                    :placeholder="'Lựa chọn loại quy chuẩn'"
                    @input="handleCheckedInput()"
                  >
                    <template v-slot:no-options="{ searching }">
                      <template v-if="searching">
                        Không có kết quả.
                      </template>
                      <em
                        v-else
                      >Không có dữ liệu</em>
                    </template>
                  </v-select>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm
            >
              <b-form-group>
                <label for="InputHelp">Giới hạn tối thiểu</label>
                <b-form-input
                  v-model="dataInput.dBaMin"
                  type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              lg="4"
              sm
            >
              <b-form-group>
                <label for="InputHelp">Giới hạn tối đa</label>
                <b-form-input
                  v-model="dataInput.limitValue"
                  type="number"
                />
              </b-form-group>
            </b-col>

          </b-row>
          <h3>
            <feather-icon
              icon="Volume2Icon"
              size="20"
              class="text-body"
            />
            Mức áp suất âm</h3>

          <b-row>
            <b-col
              sm
            >
              <b-form-group>
                <label for="InputHelp">63</label>
                <b-form-input
                  v-model="dataInput.s63"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col
              sm
            >
              <b-form-group>
                <label for="basicInput">125</label>
                <b-form-input
                  v-model="dataInput.s125"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col
              sm
            >
              <b-form-group>
                <label for="InputHelp">250</label>
                <b-form-input
                  v-model="dataInput.s250"
                  type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm
            >
              <b-form-group>
                <label for="InputHelp">500</label>
                <b-form-input
                  v-model="dataInput.s500"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col
              sm
            >
              <b-form-group>
                <label for="basicInput">750</label>
                <b-form-input
                  v-model="dataInput.s750"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col
              sm
            >
              <b-form-group>
                <label for="InputHelp">1000</label>
                <b-form-input
                  v-model="dataInput.s1000"
                  type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm
            >
              <b-form-group>
                <label for="InputHelp">2000</label>
                <b-form-input
                  v-model="dataInput.s2000"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col
              sm
            >
              <b-form-group>
                <label for="basicInput">4000</label>
                <b-form-input
                  v-model="dataInput.s4000"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col
              sm
            >
              <b-form-group>
                <label for="InputHelp">8000</label>
                <b-form-input
                  v-model="dataInput.s8000"
                  type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>

import {
  BRow,
  BCol,
  BFormGroup,
  BModal,
  BFormInput,
  BForm,
  BFormTextarea,
  BButton,
} from 'bootstrap-vue'
import { required } from '@validations'

import VSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    BForm,
    BFormTextarea,
    BButton,
    DateTimePicker,
    VSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    dataDetail: {
      type: Object,
      default: _ => {},
    },
  },
  data() {
    return {
      required,
      dataCombobox: [],
      dataInput: {
        name: '',
        noiseGroupId: 0,
        limitValue: 0,
        s63: 0,
        s125: 0,
        s250: 0,
        s500: 0,
        s750: 0,
        s1000: 0,
        s2000: 0,
        s4000: 0,
        s8000: 0,
        dBaMin: 0,
        dBaMax: 0,
      },
      isCheckInput: true,
      name: {
        required: 'Tên vị trí hoặc loại quy chuẩn là bắt buộc',
      },
      noiseGroupId: {
        required: 'Tên vị trí hoặc loại quy chuẩn là bắt buộc',
      },
    }
  },
  watch: {
    dataDetail(val) {
      if (val) {
        this.dataInput = {
          name: this.dataDetail.name,
          noiseGroupId: this.dataDetail.noiseGroupId,
          limitValue: this.dataDetail.limitValue,
          s63: this.dataDetail.s63,
          s125: this.dataDetail.s125,
          s250: this.dataDetail.s250,
          s500: this.dataDetail.s500,
          s1000: this.dataDetail.s1000,
          s2000: this.dataDetail.s2000,
          s4000: this.dataDetail.s4000,
          s8000: this.dataDetail.s8000,
          dBaMin: this.dataDetail.dBaMin,
        }
      }
    },
  },
  created() {
    this.getCombobox()
  },
  methods: {
    handleCreate(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
    },
    resetData() {
      this.dataInput = {
        name: '',
        noiseGroupId: 0,
        limitValue: 0,
        s63: 0,
        s125: 0,
        s250: 0,
        s500: 0,
        s750: 0,
        s1000: 0,
        s2000: 0,
        s4000: 0,
        s8000: 0,
        dBaMin: 0,
        dBaMax: 0,
      }
    },
    async getCombobox() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_NOISE)
      this.dataCombobox = data?.data
    },
    handleCheckedInput() {
      if (this.dataInput.name || this.dataInput.noiseGroupId) {
        this.isCheckInput = false
      } else {
        this.isCheckInput = true
      }
    },
  },

}
</script>

<style lang="scss">
.modal-report {
  .action {
    display: flex;
    justify-content: end;
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.id,"size":"sm","title":_vm.title,"cancel-title":"Hủy bỏ","cancel-variant":"outline-primary","ok-title":"Lưu","centered":""},on:{"ok":function($event){return _vm.handleCreate($event)},"hidden":_vm.resetData}},[_c('div',{staticClass:"modal-report"},[_c('validation-observer',{ref:"rule"},[_c('b-form',{staticClass:"content-form"},[_c('b-form-group',[_c('label',{attrs:{"for":"basicInput"}},[_vm._v("Loại quy chuẩn bụi"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"rules":"required","custom-messages":_vm.dustGroupId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (item) { return item.key; },"label":"value","disabled":_vm.type === 'edit' ? true : false,"options":_vm.dataCombobox,"placeholder":'Lựa chọn loại quy chuẩn bụi'},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.dataInput.dustGroupId),callback:function ($$v) {_vm.$set(_vm.dataInput, "dustGroupId", $$v)},expression:"dataInput.dustGroupId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Nồng độ hô hấp"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"respiratory","rules":"required","custom-messages":_vm.respiratory},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.respiratory),callback:function ($$v) {_vm.$set(_vm.dataInput, "respiratory", $$v)},expression:"dataInput.respiratory"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Nồng độ toàn phần"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"full","rules":"required","custom-messages":_vm.full},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.full),callback:function ($$v) {_vm.$set(_vm.dataInput, "full", $$v)},expression:"dataInput.full"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-modal
    :id="id"
    size="sm"
    :title="title"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    @ok="handleCreate($event)"
    @hidden="resetData"
  >
    <div class="modal-report">
      <validation-observer ref="rule">
        <b-form class="content-form">

          <b-form-group>
            <label for="basicInput">Tên quy chuẩn<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              :custom-messages="name"
            >
              <b-form-input
                v-model="dataInput.name"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="InputHelp">Giá trị<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="limitValue"
              rules="required"
              :custom-messages="limitValue"
            >
              <b-form-input
                v-model="dataInput.limitValue"
                type="number"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>

import {
  BFormGroup, BModal, BFormInput, BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    dataDetail: {
      type: Object,
      default: _ => {},
    },
  },
  data() {
    return {
      required,
      name: {
        required: 'Tên quy chuẩn là bắt buộc',
      },
      limitValue: {
        required: 'Giá trị là bắt buộc',
      },
      dataCombobox: [],
      dataInput: {
        name: '',
        limitValue: '',
      },
    }
  },
  watch: {
    dataDetail(val) {
      if (val) {
        this.dataInput = {
          name: this.dataDetail.name,
          limitValue: this.dataDetail.limitValue,
        }
      }
    },
  },
  methods: {
    handleCreate(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
    },
    resetData() {
      this.dataInput = {
        name: '',
        limitValue: '',
      }
    },
  },

}
</script>

<style lang="scss">
.modal-report {
  .action {
    display: flex;
    justify-content: end;
  }
}
</style>

<template>
  <b-modal
    :id="id"
    size="lg"
    :title="title"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    @ok="handleCreate($event)"
    @hidden="resetData"
  >
    <div class="modal-report">
      <h3>
        <feather-icon
          icon="InfoIcon"
          size="20"
          class="text-body"
        />
        Thông tin vị trí</h3>

      <validation-observer ref="rule">
        <b-form class="content-form">
          <b-row>
            <b-col sm>
              <b-form-group>

                <validation-provider
                  #default="{ errors }"
                  name="name"
                  :rules="isCheckInput ? 'required': ''"
                  :custom-messages="name"
                >
                  <label for="InputHelp">Tên vị trí</label>
                  <b-form-input
                    v-model="dataInput.name"
                    :state="errors.length > 0 ? false:null"
                    @input="handleCheckedInput()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm>
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="radiationGroupId"
                  :rules="isCheckInput ? 'required': ''"
                  :custom-messages="radiationGroupId"
                >
                  <label for="basicInput">Loại quy chuẩn</label>
                  <v-select
                    v-model="dataInput.radiationGroupId"
                    :reduce="item => item.key"
                    label="value"
                    :options="dataCombobox"
                    :placeholder="'Lựa chọn loại quy chuẩn'"
                    @input="handleCheckedInput()"
                  >
                    <template v-slot:no-options="{ searching }">
                      <template v-if="searching">
                        Không có kết quả.
                      </template>
                      <em
                        v-else
                      >Không có dữ liệu</em>
                    </template>
                  </v-select>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm>
              <b-form-group>
                <label for="InputHelp">Giới hạn bức xạ tia x</label>
                <b-form-input
                  v-model="dataInput.limitValue"
                  type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <h3>
            <feather-icon
              icon="ZapIcon"
              size="20"
              class="text-body"
            />
            Giới hạn</h3>

          <b-row>
            <b-col sm>
              <b-form-group class="form-group-custom">
                <label for="InputHelp">Nhân viên bức xạ</label>
                <b-form-input
                  v-model="dataInput.staff"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col sm>
              <b-form-group class="form-group-custom">
                <label for="basicInput">Người học việc, học nghề, sinh viên từ 16-18 tuổi</label>
                <b-form-input
                  v-model="dataInput.s1618"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col sm>
              <b-form-group class="form-group-custom">
                <label for="InputHelp">Công chúng</label>
                <b-form-input
                  v-model="dataInput.sPeople"
                  type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>

import {
  BRow,
  BCol,
  BFormGroup,
  BModal,
  BFormInput,
  BForm,
  BFormTextarea,
  BButton,
} from 'bootstrap-vue'
import { required } from '@validations'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VSelect from 'vue-select'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    BForm,
    BFormTextarea,
    BButton,
    DateTimePicker,
    VSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    dataDetail: {
      type: Object,
      default: _ => {},
    },
  },
  data() {
    return {
      dataCombobox: [],
      dataInput: {
        name: '',
        radiationGroupId: 0,
        limitValue: 0,
        staff: 0,
        s1618: 0,
        sPeople: 0,
      },
      isCheckInput: true,
      name: {
        required: 'Tên vị trí hoặc loại quy chuẩn là bắt buộc',
      },
      radiationGroupId: {
        required: 'Tên vị trí hoặc loại quy chuẩn là bắt buộc',
      },
    }
  },
  watch: {
    dataDetail(val) {
      if (val) {
        this.dataInput = {
          name: this.dataDetail.name,
          radiationGroupId: this.dataDetail.radiationGroupId,
          limitValue: this.dataDetail.limitValue,
          staff: this.dataDetail.staff,
          s1618: this.dataDetail.s1618,
          sPeople: this.dataDetail.sPeople,
        }
      }
    },
  },
  created() {
    this.getCombobox()
  },
  methods: {
    handleCreate(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
    },
    async getCombobox() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_RADITION)
      this.dataCombobox = data?.data
    },
    resetData() {
      this.dataInput = {
        name: '',
        radiationGroupId: 0,
        limitValue: 0,
        staff: 0,
        s1618: 0,
        sPeople: 0,
      }
    },
    handleCheckedInput() {
      if (this.dataInput.name || this.dataInput.radiationGroupId) {
        this.isCheckInput = false
      } else {
        this.isCheckInput = true
      }
    },
  },

}
</script>

<style lang="scss">
.modal-report {
  .action {
    display: flex;
    justify-content: end;
  }
  .content-form {
    .form-group-custom {
      height: calc(100% - 1rem);
      div {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
}
</style>

<template>
  <b-modal
    :id="id"
    size="lg"
    :title="title"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    @ok="handleCreate($event)"
    @hidden="resetData"
    @show="fecthCombobox()"
  >
    <div class="modal-report">
      <h3>
        <feather-icon
          icon="InfoIcon"
          size="20"
          class="text-body"
        />
        Thông tin vị trí</h3>
      <b-form class="content-form">
        <b-row>

          <b-col sm>
            <b-form-group>
              <label for="InputHelp">Tên vị trí</label>
              <b-form-input
                v-model="dataInput.name"
              />
            </b-form-group>
          </b-col>
          <b-col sm>
            <b-form-group>
              <label for="basicInput">Loại quy chuẩn</label>
              <v-select
                v-model="dataInput.vibrateGroupId"
                :reduce="item => item.key"
                label="value"
                :options="dataCombobox"
                :placeholder="'Lựa chọn loại quy chuẩn'"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col sm>
            <b-form-group>
              <label for="InputHelp">Giới hạn cho phép</label>
              <b-form-input
                v-model="dataInput.limitValue"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <h3>
          <feather-icon
            icon="ActivityIcon"
            size="20"
            class="text-body"
          />
          Gia tốc</h3>

        <b-row>
          <b-col
            sm
            lg="4"
          >
            <b-form-group>
              <label for="InputHelp">Rung đứng</label>
              <b-form-input
                v-model="dataInput.accelerationStanding"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm
            lg="4"
          >
            <b-form-group>
              <label for="basicInput">Rung ngang</label>
              <b-form-input
                v-model="dataInput.accelerationHorizontal"
                type="number"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <h3>
          <feather-icon
            icon="ActivityIcon"
            size="20"
            class="text-body"
          />
          Tốc độ</h3>

        <b-row>
          <b-col
            sm
            lg="4"
          >
            <b-form-group>
              <label for="basicInput">Rung đứng</label>
              <b-form-input
                v-model="dataInput.speedStanding"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm
            lg="4"
          >
            <b-form-group>
              <label for="InputHelp">Rung ngang</label>
              <b-form-input
                v-model="dataInput.speedHorizontal"
                type="number"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </b-modal>
</template>

<script>

import {
  BRow,
  BCol,
  BFormGroup,
  BModal,
  BFormInput,
  BForm,
  BFormTextarea,
  BButton,
} from 'bootstrap-vue'
import { required } from '@validations'

import VSelect from 'vue-select'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    BForm,
    BFormTextarea,
    BButton,
    DateTimePicker,
    VSelect,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    dataDetail: {
      type: Object,
      default: _ => {},
    },
  },
  data() {
    return {
      required,
      dataCombobox: [],
      dataInput: {
        name: '',
        vibrateGroupId: 0,
        limitValue: 0,
        accelerationStanding: 0,
        accelerationHorizontal: 0,
        speedStanding: 0,
        speedHorizontal: 0,
      },
    }
  },
  watch: {
    dataDetail(val) {
      if (val) {
        this.dataInput = {
          name: this.dataDetail.name,
          vibrateGroupId: this.dataDetail.vibrateGroupId,
          limitValue: this.dataDetail.limitValue,
          accelerationStanding: this.dataDetail.accelerationStanding,
          accelerationHorizontal: this.dataDetail.accelerationHorizontal,
          speedStanding: this.dataDetail.speedStanding,
          speedHorizontal: this.dataDetail.speedHorizontal,
        }
      }
    },
  },
  methods: {
    handleCreate(bvModalEvt) {
      // bvModalEvt.preventDefault()
      // this.$refs.rule.validate().then(success => {
      // if (success) {
      this.$emit('accept', this.dataInput)
      // }
      // })
    },
    async fecthCombobox() {
      if (this.dataCombobox.length === 0) {
        const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_VIBRATELEVEL)
        this.dataCombobox = data?.data
      }
    },
    resetData() {
      this.dataInput = {
        name: '',
        vibrateGroupId: 0,
        limitValue: 0,
        accelerationStanding: 0,
        accelerationHorizontal: 0,
        speedStanding: 0,
        speedHorizontal: 0,
      }
    },
  },

}
</script>

<style lang="scss">
.modal-report {
  .action {
    display: flex;
    justify-content: end;
  }
}
</style>
